.cards {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    /* justify-content: space-between; */
    gap: 20px;
  }


  
  .card {
    /* flex: 0 0 calc(33.33% - 20px); */
    max-width: calc(33.33% - 20px);
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 20px;
    transition: 0.3s;
    border: 0.5px solid transparent;
    min-width: 400px;
    
    height: 200px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  
  .card.expanded {
    height: auto;
  }
  
  .output {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    margin-top: 5px;
    font-size: .875rem;
    line-height: 1.25rem;

  }
  
  .card.expanded .output {
    -webkit-line-clamp: initial;
  }
  
  .card:hover {
    border-color: #1d39c4;
  }
  
  .btn {
    margin-top: 10px;
    border: none;
    outline: none;
    /* background: #1d39c4;
    color: #fff; */
    border-radius: 10px;
    max-width: 40px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    /* border: 1px solid #1d39c4; */
  }
  
  .btn:hover {
    /* background: transparent;
    color: #1d39c4; */
  }

  

  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .icon-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
  }
  
  .icon-button.delete svg {
    stroke: #ff4d4d;
    transition: .3s;
  }
  
  .icon-button.delete:hover svg {
    stroke: #b06666;
   
  }

  .search-bar{
    margin-bottom: 20px;
  }